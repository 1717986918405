import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { Box } from '@material-ui/core'
const _Tab = (props) => {
  const { blok } = props

  return (
    <SbEditable content={blok}>
      <Box maxWidth={1440} mx="auto" width="100%">
        {renderBloks(blok.content)}
      </Box>
    </SbEditable>
  )
}

export default _Tab
